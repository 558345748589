/* eslint-disable import/no-mutable-exports */
/* eslint-disable import/named */

import { NuxtAxiosInstance } from '@nuxtjs/axios'
let $axios: NuxtAxiosInstance

export function initialize(axiosInstance: NuxtAxiosInstance) {
  $axios = axiosInstance
}

export { $axios }
