










import { Component, Vue } from 'nuxt-property-decorator'

@Component
export default class Logo extends Vue {
  get logoSize(): { width: number; height: number } {
    return this.$vuetify.breakpoint.xsOnly
      ? { width: 112, height: 32 }
      : { width: 140, height: 40 }
  }
}
