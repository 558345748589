import { getterTree, mutationTree, actionTree } from 'typed-vuex'

import { $axios } from '~/utils/Api'
import { DefaultSnackbar } from '~/constants'
import { Snackbar, Constants } from '~/model'

const prepareConstants = (list: never[]) => {
  return list.reduce((acc, item: any) => {
    acc[item.value.toString()] = item.name
    return acc
  }, {} as Record<string, any>)
}

type StateStore = {
  loading: boolean
  snackbar: Snackbar
  constants: Constants
}

const state = (): StateStore => ({
  loading: false,
  snackbar: DefaultSnackbar,
  constants: {
    education_type: { values: [] },
    loan_purpose: { values: [] },
    marital_status: { values: [] },
    org_activity_type: { values: [] },
    position_level: { values: [] },
    work_activity_type: { values: [] },
    work_type: { values: [] },
  },
})

const getters = getterTree(state, {
  snackbar: (state) => state.snackbar,
  constants: (state) => {
    return {
      workType: state.constants?.work_type?.values || [],
      workActivitуType: state.constants?.work_activity_type?.values || [],
      positionLevel: state.constants?.position_level?.values || [],
      orgActivityType: state.constants?.org_activity_type?.values || [],
      maritalStatus: state.constants?.marital_status?.values || [],
      loanPurpose: state.constants?.loan_purpose?.values || [],
      educationType: state.constants?.education_type?.values || [],
    }
  },
  constantsDict: (state) => {
    return {
      workType: prepareConstants(state.constants?.work_type?.values || []),
      workActivitуType: prepareConstants(
        state.constants?.work_activity_type?.values || []
      ),
      positionLevel: prepareConstants(
        state.constants?.position_level?.values || []
      ),
      orgActivityType: prepareConstants(
        state.constants?.org_activity_type?.values || []
      ),
      maritalStatus: prepareConstants(
        state.constants?.marital_status?.values || []
      ),
      loanPurpose: prepareConstants(
        state.constants?.loan_purpose?.values || []
      ),
      educationType: prepareConstants(
        state.constants?.education_type?.values || []
      ),
    }
  },
})

const mutations = mutationTree(state, {
  SET_SNACKBAR: (state, value: Snackbar): void => {
    state.snackbar = value
  },

  SET_LOADING: (state, value: boolean): void => {
    state.loading = value
  },

  SET_CONSTANTS: (state, data: Constants): void => {
    state.constants = { ...data }
  },
})

const actions = actionTree(
  { state, getters, mutations },
  {
    showSnackbar({ commit }, value: Snackbar): void {
      commit('SET_SNACKBAR', { active: true, ...value })
    },

    async getConstants(
      { commit },
      directory_name: string = 'BANK_DIRECTORIES'
    ): Promise<void> {
      try {
        const { directory } = (
          await $axios.get('/api/v1/directories/const', {
            params: { directory_name },
          })
        ).data

        commit('SET_CONSTANTS', directory)
      } catch (e) {
        console.error('getConstants error:', e)
        throw e
      }
    },
  }
)

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
