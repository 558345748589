import { Store } from 'vuex'
import anket from './modules/anket'
import common from './modules/common'
import otp from './modules/otp'
import offers from './modules/offers'

const strict = process.env.NODE_ENV !== 'production'

const store = new Store({
  modules: {
    anket,
    common,
    otp,
    offers,
  },
  strict,
})

export default () => store
