import { Anket, AnketHeadersMapType, AddressSearch } from '~/model'

export enum Gender {
  MALE = 'M',
  FEMALE = 'F',
}

export const GenderMap: { [key in Gender]: string } = {
  [Gender.MALE]: 'Мужской',
  [Gender.FEMALE]: 'Женский',
}

export enum Workativity {
  WORKACTIVITY = 'WORKACTIVITY.1',
}

export const WorkActivityNonWorkers = ['WORKACTIVITY.6', 'WORKACTIVITY.7']

export const Form: Anket = {
  id: 0,
  loan_amount: 100000,
  loan_term: 6,
  area: {
    code: null,
    city: '',
    region: '',
  },
  last_name: '',
  first_name: '',
  middle_name: '',

  passport: '',
  passport_issue_date: null,
  birth_date: null,

  email_address: '',
  phone_number: '',
  consent_process_personal_data: true,
  consent_request_bki: false,
  consent_request_to_pfr: false,
  consent_receive_info_from_bank: true,

  passport_authority: '',
  passport_authority_code: '',
  same_registered_address: false, // не для api
  same_work_address: false, // не для api

  additional_info: {
    gender: null,
    birth_place: '',
    snils: '',
    average_monthly_income: null,
    additional_phone: '',
    seniority: null,
    org_legal_address: null,
    company_name: '',
    work_phone: '',
    position_name: '',
    registration_date: null,
    registered_address: null,
    residential_address: null,
    loan_purpose_description: '',

    additional_month_income: null,
    org_vat: '',
    org_activity_type_description: '',
    start_work_date: null,
    work_activity_type_description: '',
    org_actual_address: null,
    loan_purpose: '',
    work_type: '',
    org_activity_type: '',
    work_activity_type: '',
    position_level: '',
    education_type: '',
    marital_status: '',
  },
}

export const LocationRequiredFields: string[] = ['block', 'house']

export enum AnketHeaders {
  GENERAL = 'general',
  EXTRA = 'extra',
  PASSPORT = 'passport',
  ADDRESS = 'address',
  WORK = 'work',
  FOOTER = 'footer',
}

export const AnketHeadersMap: AnketHeadersMapType = {
  [AnketHeaders.GENERAL]: 'Основные данные',
  [AnketHeaders.EXTRA]: 'Дополнительная информация',
  [AnketHeaders.PASSPORT]: 'Паспортные данные',
  [AnketHeaders.ADDRESS]: 'Адреса',
  [AnketHeaders.WORK]: 'Работа',
  [AnketHeaders.FOOTER]: '',
}

export const AnketHeadersFields: {
  [key in AnketHeaders]: { title: string | null; fields: string[] }
} = {
  [AnketHeaders.GENERAL]: {
    title: AnketHeadersMap[AnketHeaders.GENERAL],
    fields: [
      'area',
      'loan_amount',
      'loan_term',
      'last_name',
      'first_name',
      'middle_name',
      'passport_serial',
      'passport_number',
      'passport_issue_date',
      'birth_date',
      'consent_process_personal_data',
      'consent_receive_info_from_bank',
    ],
  },
  [AnketHeaders.EXTRA]: {
    title: AnketHeadersMap[AnketHeaders.EXTRA],
    fields: [
      'additional_info.work_type',
      'email_address',
      'additional_info.additional_phone',
      'additional_info.gender',
      'additional_info.education_type',
      'additional_info.marital_status',
      'additional_info.birth_place',
      'additional_info.loan_purpose',
    ],
  },
  [AnketHeaders.PASSPORT]: {
    title: AnketHeadersMap[AnketHeaders.PASSPORT],
    fields: ['passport_authority', 'passport_authority_code'],
  },
  [AnketHeaders.ADDRESS]: {
    title: AnketHeadersMap[AnketHeaders.ADDRESS],
    fields: [
      'additional_info.registration_date',
      'additional_info.registered_address',
      'additional_info.residential_address',
    ],
  },
  [AnketHeaders.WORK]: {
    title: AnketHeadersMap[AnketHeaders.WORK],
    fields: [
      'additional_info.company_name',
      'additional_info.org_vat',
      'additional_info.work_phone',
      'additional_info.position_name',
      'additional_info.org_legal_address',
      'additional_info.org_actual_address',
      'additional_info.org_activity_type',
      'additional_info.work_activity_type',
      'additional_info.position_level',
      'additional_info.start_work_date',
      'additional_info.average_monthly_income',
      'additional_info.additional_month_income',
      'additional_info.seniority',
    ],
  },
  [AnketHeaders.FOOTER]: {
    title: null,
    fields: ['consent_request_to_pfr', 'consent_request_bki'],
  },
}

export const AddressSearchDefault: AddressSearch = {
  search: null,
  result: [],
  isLoading: false,
}

export const AlertOpts = {
  error: {
    icon: 'ft-alert-triangle',
    color: 'error',
    text: 'Исправьте ошибки в полях выделенные красным цветом',
    outlined: true,
  },

  refill: {
    icon: 'ft-info-circle',
    color: 'light-green',
    text: 'Чтобы отправить заявку на кредит в эту организацию, нужно  заполнить еще несколько полей анкеты',
    outlined: false,
  },

  confirm: {
    icon: 'ft-info-circle',
    color: 'light-green',
    text: 'Проверьте данные перед отправкой в финансовую организацию — важно, чтобы все данные были верны',
    outlined: false,
  },

  edit: {
    icon: 'ft-info-circle',
    color: 'light-green',
    text: 'Отредактируйте данные, которые были указаны неверно',
    outlined: false,
  },

  serverError: {
    icon: 'ft-alert-triangle',
    color: 'error',
    text: 'Произошла ошибка',
    outlined: true,
  },
}

export enum FormOtherValue {
  OtherLoanPurpose = 'OtherTarget',
  OtherOrgActivityType = 'ACTIVITY.TYPE.SER.19',
  OtherWorkActivityType = 'CLIENT.ACTIVITY.SCOPE.14',
}
