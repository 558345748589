












import { Component, Vue } from 'nuxt-property-decorator'
import { MetaInfo } from 'vue-meta'

@Component
export default class Error404 extends Vue {
  head(): MetaInfo {
    return {
      title: 'Страница не найдена',
    }
  }
}
