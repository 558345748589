import { OfferStatusItem } from '~/model'

export enum OfferStatus {
  NEW = 'NEW',
  ERROR = 'ERROR',
  SENT = 'SENT',
  NOT_ACCEPTED = 'NOT_ACCEPTED',
  IN_WORK = 'IN_WORK',
  REJECTED = 'REJECTED',
  APPROVED = 'APPROVED',
  ACCEPTED = 'ACCEPTED',
}

const SentStatusItem: OfferStatusItem = {
  text: 'Заявка отправлена',
  icon: 'ft-clock',
  color: '#237BFF',
}

export const MapStatuses: { [key in OfferStatus]: OfferStatusItem } = {
  [OfferStatus.SENT]: SentStatusItem,
  [OfferStatus.ERROR]: SentStatusItem,
  [OfferStatus.NEW]: SentStatusItem,
  [OfferStatus.NOT_ACCEPTED]: {
    text: 'Банк не принял заявку',
    icon: 'ft-file-times',
    color: '#FB7429',
  },

  [OfferStatus.IN_WORK]: {
    text: 'Заявка принята банком в обработку',
    icon: 'ft-building',
    color: '#6A36FF',
  },

  [OfferStatus.REJECTED]: {
    text: 'Вам отказали',
    icon: 'ft-remove',
    color: '#F6444D',
  },

  [OfferStatus.APPROVED]: {
    text: 'Заявка одобрена',
    icon: 'ft-file-check',
    color: '#05B6E5',
  },

  [OfferStatus.ACCEPTED]: {
    text: 'Произошла выдача',
    icon: 'ft-usd-circle',
    color: '#4DC912',
  },
}

export enum OfferTypes {
  SUITABLE = 'suitable_offers',
  SIMILAR = 'similar_offers',
  PRE = 'pre_offers',
}

export const PreOfferStatus: OfferStatusItem = {
  text: 'Предварительно одобрено',
  icon: 'ft-check',
  color: '#237BFF',
}

export const OfferStatusDefault: OfferStatusItem = {
  text: '',
  icon: '',
  color: '',
}
