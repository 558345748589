













import { Component, Vue, Prop } from 'nuxt-property-decorator'
import { MetaInfo } from 'vue-meta'

@Component
export default class Error500 extends Vue {
  @Prop({ type: Object, default: () => {} })
  error!: Record<string, unknown>

  head(): MetaInfo {
    return {
      title: 'Жуткая ошибка',
    }
  }
}
