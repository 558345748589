import { getterTree, mutationTree, actionTree } from 'typed-vuex'
import merge from 'lodash/merge'
import cloneDeep from 'lodash/cloneDeep'
import isMatch from 'lodash/isMatch'
import omit from 'lodash/omit'
import { $axios } from '~/utils/Api'
import {
  prepareNestedCheckFields,
  prepareSubmitForm,
  removeObjectsWithNull,
} from '~/utils/Helper'

import { Anket, SearchResult, Offer } from '~/model'

import {
  PeriodRateLabel,
  PeriodRate,
  AmountLabel,
  Form,
  WorkActivityNonWorkers,
} from '~/constants'

type StateStore = {
  search_result: {
    rate: string
    unit: PeriodRate
    monthly_payment: string
    full_amount: string
  }
  form: typeof Form // форма на фронте
  raw_form: typeof Form // перевоначальная неизмененная [на фронте] анкета
  required_fields: Record<string, boolean>
  offer: null | Offer
}

const state = (): StateStore => ({
  search_result: {
    rate: '',
    unit: PeriodRate.PER_DAY,
    monthly_payment: '-',
    full_amount: '-',
  },
  form: cloneDeep(Form), // datafomr frontend ( reactive  data bind)
  raw_form: cloneDeep(Form), // data from backend
  required_fields: {},
  offer: null,
})

const getters = getterTree(state, {
  form: (state) => cloneDeep(state.form),
  isWorker: (state) =>
    !WorkActivityNonWorkers.includes(
      state.form.additional_info.work_type as string
    ),

  searchForm: (state) => {
    const { area, loan_term, loan_amount } = state.form

    return {
      area,
      loan_term,
      loan_amount: String(loan_amount).replace(/\D/g, ''),
    }
  },

  searchResult: (state): any => {
    const { monthly_payment, rate, full_amount, unit } = state.search_result

    return {
      amountLabel: AmountLabel[unit],
      rateLabel: PeriodRateLabel[unit],
      rate: rate || '-',
      amount: unit === PeriodRate.PER_DAY ? full_amount : monthly_payment,
    }
  },

  isValidSearchForm: (state) =>
    !!state.form.loan_amount &&
    !!state.form.loan_term &&
    !!state.form.area.code,

  // только те, что с true (api checkDetails)
  requireToFill: (state) => prepareNestedCheckFields(state.required_fields),

  // все поля: и true, и false (api checkDetails)
  requiredFields: (state) =>
    prepareNestedCheckFields(state.required_fields, false),
})

const mutations = mutationTree(state, {

  /**
   * Сумма и срок на главной странице не отправляются на бек
   * Эти данные хранятся на фронте в ls
   * На др страницах fetch анкеты (вызов в middleware авторизованых страниц) возвращает все данные
   * в т.ч и  сумму, и срок, которые могут переписать раннее установленные сумму и срок
   * Поэтому мы выкинем эти поля из ответа fetch, используя lodash.omit
   * @param state 
   * @param form 
   * @returns 
   */
  FETCH_FORM: (state, form: Anket): void => {
    if(isMatch({}, form)) return

    state.form = merge(state.form, omit(form, ['loan_amount', 'loan_term', 'area']))

    if (form.id && form.id > 0)
      window.localStorage.setItem('anket_id', String(form.id))
  },

  /**
   * Полностью обновляет всю анкету возвращенная фронту с бека
   * @param state 
   * @param form 
   */
  SET_FORM: (state, form: Partial<Anket>): void => {
    state.form = merge(state.form, form)
  },

  SET_RAW_FORM: (state, form: Anket): void => {
    state.raw_form = { ...form }
  },

  SET_SEARCH_RESULT: (state, result: SearchResult): void => {
    state.search_result = { ...result }
  },

  SET_REQUIRED_ANKET_FIELDS: (state, fields: Record<string, boolean>): void => {
    state.required_fields = fields
  },

  SET_OFFER: (state, offer: Offer): void => {
    state.offer = offer
  },

  RESET_STORE: (state): void => {
    state.search_result = {
      rate: '',
      unit: PeriodRate.PER_DAY,
      monthly_payment: '-',
      full_amount: '-',
    }
    state.form = cloneDeep(Form)
    state.raw_form = cloneDeep(Form)
    state.required_fields = {}
    state.offer = null
  },
})

const actions = actionTree(
  { state, getters, mutations },
  {
    // submit general data fields
    async complete({ state }): Promise<void> {
      try {
        await $axios.post(
          '/api/v2/loan-requests',
          removeObjectsWithNull(prepareSubmitForm(state.form))
        )
      } catch (e) {
        console.error('complete anket error:', e)
        throw e
      }
    },

    // submit required data fields
    async refill({ state, commit }): Promise<void> {
      try {
        const anket_id = window.localStorage.getItem('anket_id')

        const form = (
          await $axios.patch(
            `/api/v2/loan-requests/${anket_id}`,
            removeObjectsWithNull(prepareSubmitForm(state.form))
          )
        ).data
        commit('SET_FORM', form)
      } catch (e) {
        console.error('refill anket error:', e)
        throw e
      }
    },

    // submit edit
    async edit({ state, commit }): Promise<void> {
      const rawAnket = isMatch(state.raw_form, {})
        ? state.raw_form
        : removeObjectsWithNull(prepareSubmitForm(state.raw_form))

      const newAnket = removeObjectsWithNull(prepareSubmitForm(state.form))

      console.info('send form: ', !isMatch(rawAnket, newAnket))

      if (isMatch(rawAnket, newAnket)) return

      try {
        const form = (await $axios.post('/api/v2/loan-requests', newAnket)).data
        commit('SET_FORM', form)
      } catch (e) {
        console.error('edit anket error:', e)
        throw e
      }
    },

    async fetchAnket({ commit }): Promise<void> {
      try {
        const form = (await $axios.get('/api/v2/latest-loan-request')).data
        commit('SET_RAW_FORM', form)
        commit('FETCH_FORM', form)
      } catch (e) {
        console.error('fetch anket error')
        throw e
      }
    },

    // Get required fields
    async checkDetails({ state, commit }): Promise<void> {
      try {
        const anket_id = window.localStorage.getItem('anket_id')

        const fields = (
          await $axios.get(
            `/api/v1/private/loan-requests/${anket_id}/offer/${state.offer?.id}/check-fields`
          )
        ).data

        commit('SET_REQUIRED_ANKET_FIELDS', fields)
      } catch (e) {
        console.error('check details error:', e)
        throw e
      }
    },

    async address(_, query: string): Promise<void> {
      try {
        return (await $axios.post('/api/v1/suggestions/address', { query }))
          .data
      } catch (e) {
        console.error('address error:', e)
        throw e
      }
    },

    async areaSearch(_, query: string): Promise<Location[]> {
      try {
        return (
          await $axios.get('/api/v1/area-search', {
            params: { query },
          })
        ).data
      } catch (e) {
        console.error('areaSearch error:', e)
        throw e
      }
    },

    async rateGuide({ commit, state }): Promise<void> {
      const { loan_amount, loan_term } = prepareSubmitForm(state.form)

      try {
        const result = (
          await $axios.get('/api/v1/directories/rate-guide', {
            params: { loan_amount, loan_term },
          })
        ).data

        commit('SET_SEARCH_RESULT', result)
      } catch (e) {
        console.error('search error:', e)
        throw e
      }
    },

    async getGeoIp({ commit }): Promise<void> {
      try {
        const area = (await $axios.get('/api/v1/areas/iplocate')).data
        commit('SET_FORM', { area })
      } catch (e) {
        console.error('getGeoIp error:', e)
        throw e
      }
    },
  }
)

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
