import { Context, Middleware } from '@nuxt/types'
import { TokenableScheme } from '@nuxtjs/auth-next'
import { parseJwt } from '~/utils/Helper'

const fetchAnketMiddleware: Middleware = async ({
  store,
  $auth,
}: Context): Promise<void> => {
  try {
    await store.dispatch('anket/fetchAnket')
    const accessToken: string | boolean = (
      $auth.strategy as TokenableScheme
    ).token.get()

    if (accessToken) {
      const token = (accessToken as string).split(' ')[1]
      const { phone_number } = parseJwt(token)
      store.commit('anket/SET_FORM', { phone_number })
    }
  } catch (e: unknown) {
    Promise.reject(e)
  }
}

export default fetchAnketMiddleware
