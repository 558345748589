import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VSheet } from 'vuetify/lib/components/VSheet';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VSheet,[_c(VAppBar,{attrs:{"color":"white","flat":""}},[_c(VContainer,{staticClass:"fill-height flex-nowrap"},[_c(VToolbarTitle,[_c('logo')],1),_vm._v(" "),_c(VSpacer),_vm._v(" "),_c('client-only',[(_vm.isShowTopCreditButton && !_vm.$auth.loggedIn)?_c(VBtn,{staticClass:"btn-outlined d-none d-sm-block",attrs:{"outlined":"","depressed":"","small":""},on:{"click":_vm.submit}},[_vm._v("Подобрать кредит")]):_vm._e(),_vm._v(" "),(_vm.isShowLogoutButton && _vm.$auth.loggedIn)?_c(VBtn,{staticClass:"btn-outlined",attrs:{"outlined":"","small":""},on:{"click":_vm.logout}},[_vm._v("Выйти")]):_vm._e()],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }