import { Context, Middleware } from '@nuxt/types'
import { Routes } from '~/constants'

const checkAuthMiddleware: Middleware = ({
  $auth,
  redirect,
}: Context): void => {
  if (!$auth.$state.loggedIn) redirect(Routes.INDEX)
}

export default checkAuthMiddleware
