








































































import { Component, Vue } from 'nuxt-property-decorator'
import Logo from '~/components/Elements/Logo/Logo.vue'

@Component({
  components: { Logo },
})
export default class AppFooter extends Vue {
}
