import { getterTree, mutationTree, actionTree } from 'typed-vuex'
import { $axios } from '~/utils/Api'
import { OfferSend, Offer } from '~/model'
import { OfferTypes } from '~/constants'

type OfferResult = { [key in OfferTypes]: Offer[] }

const state = () => ({
  suitable_offers: [],
  similar_offers: [],
  pre_offers: [],
})

const getters = getterTree(state, {
  suitable_offers: (state) =>
    state.suitable_offers.map((offer: Offer) => ({
      ...offer,
      type: OfferTypes.SUITABLE,
    })),
  similar_offers: (state) =>
    state.similar_offers.map((offer: Offer) => ({
      ...offer,
      type: OfferTypes.SIMILAR,
    })),
  pre_offers: (state) =>
    state.pre_offers.map((offer: Offer) => ({
      ...offer,
      type: OfferTypes.PRE,
    })),
  isResult: (state) =>
    !!state.similar_offers.length ||
    !!state.suitable_offers.length ||
    !!state.pre_offers.length,
})

const mutations = mutationTree(state, {
  SET_RESULT: (
    state: OfferResult,
    { suitable_offers, similar_offers, pre_offers }: OfferResult
  ): void => {
    state.suitable_offers = suitable_offers || []
    state.similar_offers = similar_offers || []
    state.pre_offers = pre_offers || []
  },
})

const actions = actionTree(
  { state, getters, mutations },
  {
    async getOffersLastLead({ commit }): Promise<void> {
      try {
        const result: OfferResult = (
          await $axios.get('/api/v1/private/offers-last-lead')
        ).data
        commit('SET_RESULT', result)
      } catch (e) {
        console.error('getOffersLastLead error:', e)
        throw e
      }
    },

    async sendOffer(_, data: OfferSend): Promise<void> {
      try {
        await $axios.post('/api/v1/private/leads', data)
      } catch (e) {
        console.error('sendOffer error:', e)
        throw e
      }
    },
  }
)

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
