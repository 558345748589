import { getterTree, mutationTree, actionTree } from 'typed-vuex'
import { $axios } from '~/utils/Api'
import { formatPhone } from '~/utils/Helper'

const state = () => ({
  phone_number: '',
})

const getters = getterTree(state, {
  rawPhoneNumber: (state) => formatPhone(state.phone_number),
})

const mutations = mutationTree(state, {
  SET_PHONE: (state, value: string): void => {
    state.phone_number = value
  },
})

const actions = actionTree(
  { state, getters, mutations },
  {
    async requestAuthPhone(
      { getters },
      recaptcha_token: string
    ): Promise<void> {
      try {
        await $axios.get('/api/v1/private/auth/phone', {
          params: {
            phone_number: getters.rawPhoneNumber,
            recaptcha_token,
          },
        })
      } catch (e) {
        console.error('requestAuthPhone error:', e)
        throw e
      }
    },

    async sendAuthPhoneToken(
      { getters },
      {
        sms_token,
        recaptcha_token,
      }: { sms_token: string; recaptcha_token: string }
    ): Promise<Record<string, any>> {
      try {
        return (
          await $axios.put('/api/v1/private/auth/phone', {
            sms_token,
            recaptcha_token,
            phone_number: getters.rawPhoneNumber,
          })
        ).data
      } catch (e) {
        console.error('sendAuthPhoneToken error:', e)
        throw e
      }
    },
  }
)

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
