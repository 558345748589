





























import { Component, Vue } from 'nuxt-property-decorator'
import { Snackbar } from '~/model'

@Component
export default class Notification extends Vue {
  get snackbar(): Snackbar {
    return this.$store.state.common.snackbar
  }

  get isShow(): boolean {
    const { text, active } = this.$store.state.common.snackbar
    return !!text && active
  }

  set isShow(active: boolean) {
    this.$store.commit('common/SET_SNACKBAR', { active })
  }
}
