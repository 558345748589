import { Component, Vue } from 'nuxt-property-decorator'
import { TimerCountdownSeconds } from '~/constants'

@Component
export default class extends Vue {
  remainSeconds: number = TimerCountdownSeconds
  currentTimer: any | null = null

  public get isActiveTimer(): boolean {
    return !!this.currentTimer
  }

  public startTimer() {
    this.remainSeconds = TimerCountdownSeconds
    this.currentTimer = setInterval(this.onTickTimer, 1000)
  }

  public onTickTimer(): void {
    this.remainSeconds -= 1
    if (!this.remainSeconds) this.resetTimer()
  }

  public resetTimer(): void {
    clearInterval(this.currentTimer)
    this.currentTimer = null
  }
}
