import { Context } from '@nuxt/types'

export default function ({ app, query }: Context) {
  Object.keys(query)
    .filter((key: string) =>
      ['partner_id', 'partner_b2b', 'click_id'].includes(key)
    )
    .forEach((key: string) =>
      app.$cookies.set(key, query[key], {
        maxAge: 60 * 60 * 24 * 30,
        path: '/',
      })
    )
}
