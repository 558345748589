






















import { Component, mixins } from 'nuxt-property-decorator'
import { LoadingMixin } from '~/mixins'
import AppBar from '~/components/Layouts/AppBar.vue'
import AppFooter from '~/components/Layouts/AppFooter.vue'
import Notification from '~/components/Elements/Notification/Notification.vue'

@Component({
  components: { AppBar, AppFooter, Notification },
})
export default class DefaultLayout extends mixins(LoadingMixin) {
  mounted(): void {
    console.info(`build: ${this.$nuxt.$config.sentry?.config?.release}`)
  }
}
