import dayjs from 'dayjs'
import { VueMaskFilter } from 'v-mask'

import isNull from 'lodash/isNull'
import isEmpty from 'lodash/isEmpty'
import forOwn from 'lodash/forOwn'
import isObject from 'lodash/isObject'
import pull from 'lodash/pull'
import isArray from 'lodash/isArray'
import cloneDeep from 'lodash/cloneDeep'
import isString from 'lodash/isString'
import omit from 'lodash/omit'

import { Anket } from '~/model'
import {
  FormOtherValue,
  GenderMap,
  DateFormat,
  ApiDateFormat,
  WorkActivityNonWorkers,
} from '~/constants'

export const phoneMask = (phone: string) =>
  VueMaskFilter(phone, '+7 ### ###-##-##')

export const getConstant = (list: Array<Record<string, any>>, key: string) => {
  const res = list.find((i) => i.value === key)
  return res ? res.name : '-'
}

export const formatPhone = (phone: string | undefined): string =>
  phone ? phone.replace(/\D/g, '') : ''

const formatAmount = (str: number): string => `${str.toLocaleString('ru-RU')} ₽`

export const prepareSubmitForm = (form: Anket): Anket => {
  const { loan_amount, additional_info } = form
  return {
    // поля для фронта, беку они не нужны
    ...omit(form, ['same_registered_address', 'same_work_address']),

    loan_amount: Number(String(loan_amount).replace(/\D/g, '')),
    additional_info: {
      ...additional_info,
      additional_phone: formatPhone(additional_info.additional_phone),
      work_phone: formatPhone(additional_info.work_phone),
      seniority: additional_info.seniority,
    },
  }
}

// based on https://stackoverflow.com/questions/32141291/javascript-reflection-get-nested-objects-path#32143089
export const prepareNestedCheckFields = (
  obj: { [x: string]: any },
  only: boolean = true,
  prefix?: string
) => {
  const keys = Object.keys(obj)

  prefix = prefix ? prefix + '.' : ''

  return keys.reduce((result, key) => {
    if (isObject(obj[key])) {
      result = result.concat(
        prepareNestedCheckFields(obj[key], only, prefix + key)
      )
    } else if (obj[key] === false || !only) {
      result.push(prefix + key)
    }
    return result
  }, [] as Array<string>)
}

export const prepareConfirmData = (
  form: Anket,
  constants: Record<string, any>
): Record<string, any> => {
  let result = {
    fullName: {
      name: 'ФИО',
      value: `${form.last_name} ${form.first_name} ${form.middle_name}`,
    },

    phone_number: {
      name: 'Телефон',
      value: phoneMask(form.phone_number),
    },

    'additional_info.gender': {
      name: 'Пол',
      value: form.additional_info.gender
        ? GenderMap[form.additional_info.gender]
        : '',
    },

    birth_date: {
      name: 'Дата рождения',
      value: dayjs(form.birth_date, ApiDateFormat).format(DateFormat),
    },

    'additional_info.birth_place': {
      name: 'Место рождения',
      value: form.additional_info.birth_place,
    },

    'additional_info.marital_status': {
      name: 'Семейное положение',
      value: getConstant(
        constants.maritalStatus,
        form.additional_info.marital_status
      ),
    },

    'additional_info.education_type': {
      name: 'Образование',
      value: getConstant(
        constants.educationType,
        form.additional_info.education_type
      ),
    },

    'additional_info.loan_purpose': {
      name: 'Цель кредита',
      value:
        form.additional_info.loan_purpose === FormOtherValue.OtherLoanPurpose
          ? form.additional_info.loan_purpose_description
          : getConstant(
              constants.loanPurpose,
              form.additional_info.loan_purpose
            ),
    },

    passport: {
      name: 'Серия и номер паспорта',
      value: form.passport,
    },

    passport_authority: {
      name: 'Кем выдан паспорт',
      value: form.passport_authority,
    },

    passport_issue_date: {
      name: 'Дата выдачи',
      value: dayjs(form.passport_issue_date, ApiDateFormat).format(DateFormat),
    },

    passport_authority_code: {
      name: 'Код подразделения',
      value: form.passport_authority_code,
    },

    'additional_info.registration_date': {
      name: 'Дата регистрации',
      value: dayjs(
        form.additional_info.registration_date,
        ApiDateFormat
      ).format(DateFormat),
    },

    'additional_info.registered_address': {
      name: 'Адрес регистрации',
      value: form.additional_info?.registered_address?.value,
    },

    'additional_info.residential_address': {
      name: 'Адрес проживания',
      value: form.additional_info?.residential_address?.value,
    },

    email_address: {
      name: 'E-mail',
      value: form.email_address,
    },

    'additional_info.additional_phone': {
      name: 'Дополнительный телефон',
      value: phoneMask(form.additional_info.additional_phone),
    },

    'additional_info.work_type': {
      name: 'Вид занятости',
      value: getConstant(constants.workType, form.additional_info.work_type),
    },
  }

  if (!WorkActivityNonWorkers.includes(form.additional_info.work_type)) {
    const workFields = {
      'additional_info.company_name': {
        name: 'Название организации',
        value: form.additional_info.company_name,
      },

      'additional_info.org_vat': {
        name: 'ИНН организации',
        value: form.additional_info.org_vat,
      },

      'additional_info.org_legal_address': {
        name: 'Юридический адрес организации',
        value: form.additional_info?.org_legal_address?.value,
      },

      'additional_info.org_actual_address': {
        name: 'Фактический рабочий адрес',
        value: form.additional_info?.org_actual_address?.value,
      },

      'additional_info.org_activity_type': {
        name: 'Направление деятельности организации',
        value:
          form.additional_info.org_activity_type ===
          FormOtherValue.OtherOrgActivityType
            ? form.additional_info.org_activity_type_description
            : getConstant(
                constants.orgActivityType,
                form.additional_info.org_activity_type
              ),
      },

      'additional_info.work_activity_type': {
        name: 'Функционал на работе',
        value:
          form.additional_info.work_activity_type ===
          FormOtherValue.OtherWorkActivityType
            ? form.additional_info.work_activity_type_description
            : getConstant(
                constants.workActivitуType,
                form.additional_info.work_activity_type
              ),
      },

      'additional_info.work_phone': {
        name: 'Рабочий телефон',
        value: phoneMask(form.additional_info.work_phone),
      },

      'additional_info.position_level': {
        name: 'Уровень должности',
        value: getConstant(
          constants.positionLevel,
          form.additional_info.position_level
        ),
      },

      'additional_info.position_name': {
        name: 'Полное название должности',
        value: form.additional_info.position_name,
      },

      'additional_info.start_work_date': {
        name: 'Дата приема на работу',
        value: form.additional_info.start_work_date,
      },

      'additional_info.average_monthly_income': {
        name: 'Средний доход в месяц',
        value: formatAmount(form.additional_info.average_monthly_income || 0),
      },

      'additional_info.seniority': {
        name: 'Общий стаж работы',
        value: `${form.additional_info.seniority} мес.`,
      },

      'additional_info.additional_month_income': {
        name: 'Дополнительный доход в месяц',
        value: !form.additional_info.additional_month_income
          ? 'Нет'
          : formatAmount(form.additional_info.additional_month_income),
      },

      consent_request_to_pfr: {
        name: 'Согласие на запрос выписки индивидуального лицевого счета в ПФР',
        value: form.consent_request_to_pfr ? 'Да' : 'Нет',
      },

      consent_request_bki: {
        name: 'Согласие на запрос в БКИ',
        value: form.consent_request_bki ? 'Да' : 'Нет',
      },
    }
    result = { ...result, ...workFields }
  }
  return result
}

// based on https://stackoverflow.com/questions/18515254/recursively-remove-null-values-from-javascript-object
export const removeObjectsWithNull = function (obj: any) {
  return (function prune(current) {
    forOwn(current, function (value, key) {
      if (
        isNull(value) ||
        (isString(value) && isEmpty(value)) ||
        (isObject(value) && isEmpty(prune(value)))
      ) {
        delete current[key]
      }
    })

    if (isArray(current)) pull(current, undefined)

    return current
  })(cloneDeep(obj))
}

// from https://stackoverflow.com/a/38552302
export const parseJwt = (token: string) => {
  const base64Url = token.split('.')[1]
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
  const jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
      })
      .join('')
  )

  return JSON.parse(jsonPayload)
}
