import dayjs from 'dayjs'
import { ApiDateFormat } from '~/constants'

export const MinDefaultDate = dayjs()
  .subtract(100, 'years')
  .format(ApiDateFormat)

export const MaxDefaultDate = dayjs().add(1, 'years').format(ApiDateFormat)
export const TodayDate = dayjs().add(0, 'days').format(ApiDateFormat)
export const AdultDate = dayjs().subtract(18, 'years').format(ApiDateFormat)
