


































import { Component, Vue } from 'nuxt-property-decorator'
import Logo from '~/components/Elements/Logo/Logo.vue'
import { Routes } from '~/constants'
import { eventBus } from '~/eventBus'

@Component({
  components: { Logo },
})
export default class AppBar extends Vue {
  get isShowLogoutButton(): boolean {
    const excludeRegExpPath = new RegExp(Routes.AUTH)
    return !excludeRegExpPath.test(this.$route.path)
  }

  get isShowTopCreditButton(): boolean {
    return [Routes.INDEX].includes(this.$route.path as Routes)
  }

  submit(): void {
    eventBus.$emit('submit-search-form')
  }

  async logout(): Promise<void> {
    await this.$auth.logout()

    await this.$router.push(Routes.INDEX, () => {
      this.$store.commit('otp/SET_PHONE', '')
      this.$store.commit('anket/RESET_STORE')

      this.$store.dispatch('common/showSnackbar', {
        text: 'Вы успешно вышли из системы',
        color: 'success',
      })
    })
  }
}
