import { Snackbar, PeriodRateType, Location } from '~/model'

export const TimerCountdownSeconds = 60

export const DefaultSnackbar: Snackbar = {
  color: 'info',
  text: '',
  active: false,
  timeout: 5000,
}
export enum Dialogs {
  OFFER = 'offer',
}

// django's API LIST structure
export const DefaultApiList = {
  count: null,
  results: [],
}
export const ApiDateFormat = 'YYYY-MM-DD'
export const DateFormat = 'DD.MM.YYYY'
export const DateTimeFormat = `${DateFormat} hh:mm`
export const SignInPageUrl = '/login'

export enum PeriodRate {
  PER_DAY = 'per_day',
  PER_YEAR = 'per_year',
}

export const PeriodRateLabel: { [key in PeriodRate]: string } = {
  [PeriodRate.PER_DAY]: 'Ставка в день',
  [PeriodRate.PER_YEAR]: 'Ставка в год',
}

export const AmountLabel: { [key in PeriodRate]: string } = {
  [PeriodRate.PER_DAY]: 'Сумма к выплате',
  [PeriodRate.PER_YEAR]: 'Ежемесячный платеж',
}

export const PeriodRates: PeriodRateType[] = [
  {
    text: '1  месяц',
    value: 1,
  },
  {
    text: '2  месяца',
    value: 2,
  },
  {
    text: '6 месяцев',
    value: 6,
  },
  {
    text: '1 год',
    value: 12,
  },
  {
    text: '2 года',
    value: 24,
  },
  {
    text: '3 года',
    value: 36,
  },
  {
    text: '5 лет',
    value: 60,
  },
  {
    text: '7 лет',
    value: 84,
  },
  {
    text: '10 лет',
    value: 120,
  },
]

export enum Routes {
  INDEX = '/',
  AUTH = '/auth',
  FORM = '/form',
  RESULT = '/form/result',
  CONFIRM = '/form/confirm',
  REFILL = '/form/re-fill',
  EDIT = '/form/edit',
}

export const DefaultLocation: Location = {
  code: null,
  city: '',
  region: '',
}
