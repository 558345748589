












import { Component, Vue, Prop } from 'nuxt-property-decorator'
import AppBar from '~/components/Layouts/AppBar.vue'
import AppFooter from '~/components/Layouts/AppFooter.vue'
import Error404 from '~/components/Error/404.vue'
import Error500 from '~/components/Error/500.vue'

@Component({
  components: { AppBar, AppFooter, Error404, Error500 },
})
export default class ErrorLayout extends Vue {
  @Prop({ type: Object, default: () => {} })
  error!: Record<string, unknown>

  get errorPage() {
    if (this.error.statusCode === 404) {
      return Error404
    }
    return Error500
  }
}
