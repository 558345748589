import { RefreshScheme } from '~auth/runtime'

export default class CustomScheme extends RefreshScheme {
  async logout() {
    try {
      await super.logout()
    } catch (e) {
      throw new Error('Could not perform logout action: ', e)
    }
  }
}
