import { Component, Vue, PropSync } from 'nuxt-property-decorator'

import Rules from '~/utils/Rules'
import { AdultDate } from '~/utils/Dates'
import { Anket } from '~/model'
import { AnketHeaders, FormOtherValue } from '~/constants'

@Component
export default class FoormSectionMixin extends Vue {
  @PropSync('form')
  innerForm!: Anket

  FormOtherValue: typeof FormOtherValue = FormOtherValue
  rules = Rules
  adultDate: string = AdultDate
  headers: typeof AnketHeaders = AnketHeaders
}
