import dayjs from 'dayjs'
import pick from 'lodash/pick'
import { formatPhone } from './Helper'
import { TodayDate, MinDefaultDate } from '~/utils/Dates'

import { ApiDateFormat, DateFormat, LocationRequiredFields } from '~/constants'
import { Address, Location, Rule } from '~/model'

type EmptyString = string | undefined

const urlReg =
  /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\\+.~#?&//=]*)/

const incorrectPhoneMsg = 'Введите корректный номер телефона'
const requiredFieldMsg = 'Поле обязательное'

class Rules {
  public static required = (
    value: EmptyString | number | boolean,
    message?: EmptyString
  ): Rule => !!value || message || requiredFieldMsg

  public static max = (
    value: EmptyString,
    max: number = 20,
    message?: EmptyString
  ): Rule =>
    (!!value && value.length <= max) ||
    message ||
    `Максимальная длина поля - ${max} симв.`

  public static min = (value: string, min?: number): Rule => {
    const length = min || 2

    return (
      (value && value.length >= length) ||
      `Минимальная длина поля - ${length} симв.`
    )
  }

  public static email = (value: string): Rule =>
    /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/.test(value) || 'Введите корректный E-mail'

  public static numAny = (value: string): Rule =>
    /(?=.*[0-9])/.test(value) || 'Поле должно содержать хотя бы одну цифру'

  public static numOnly = (value: string): Rule =>
    /^[0-9]+$/.test(value) || 'Поле должно содержать только цифры'

  public static sumOnly = (value: string): Rule =>
    /^[+-]?\d+(\.\d+)?$/.test(value) ||
    'Поле должно содержать только цифры (допускается разделитель - точка)'

  public static alphaAny = (value: string): Rule =>
    /(?=.*[a-z])/.test(value) ||
    'Поле должно содержать хотя бы одну букву в нижнем регистре'

  public static url = (value: string): Rule =>
    new RegExp(urlReg).test(value) || 'Введите корректный url'

  public static phone = (value: string): Rule => {
    const phone = formatPhone(value)

    return phone.length === 10 || incorrectPhoneMsg
  }

  public static mobPhone = (value: string): Rule => {
    const phone = formatPhone(value)

    return (
      (phone.length === 10 && String(phone).substring(0, 1) === '9') ||
      incorrectPhoneMsg
    )
  }

  public static cyrOnly = (value: string): Rule =>
    /^[а-яА-ЯёЁ\s-]*$/.test(value) || 'Поле должно содержать только кириллицу'

  public static cyrNumsOnly = (value: string): Rule =>
    /[0-9а-яА-ЯЁё -]+$/.test(value) ||
    'Поле должно содержать только кириллицу и цифры'

  public static isMinDate = (value: string) => {
    const date = dayjs(value, ApiDateFormat)

    return (
      (!!value && date.isAfter(MinDefaultDate)) ||
      'Дата должна быть не меньше: ' + dayjs(MinDefaultDate).format(DateFormat)
    )
  }

  public static isMaxDate = (
    value: string | null,
    max: string = MinDefaultDate
  ) => {
    return (
      (!!value && dayjs(value, ApiDateFormat).isBefore(max)) ||
      'Дата должна быть не позже ' + dayjs(max).format(DateFormat)
    )
  }

  public static isValidDateRange = (
    value: EmptyString | null,
    min: string = MinDefaultDate,
    max: string = TodayDate
  ) => {
    const date = dayjs(value, ApiDateFormat)

    return (
      (!!value && date.isBefore(max) && date.isAfter(min)) ||
      'Некорректная дата'
    )
  }

  public static isValidDate = (
    date: EmptyString | null,
    birthDate: EmptyString
  ) => {
    const momentPassportDate = dayjs(date, ApiDateFormat)
    const momentBirthDate = dayjs(birthDate || MinDefaultDate, ApiDateFormat)
    const targetDate = momentBirthDate.add(14, 'years')

    return (
      (!!date &&
        momentPassportDate.isBefore(TodayDate) &&
        momentPassportDate.isAfter(targetDate)) ||
      'Введите корректную дату'
    )
  }

  public static location = (location: Address) => {
    const targetFields = pick(location, LocationRequiredFields)

    const isValid = Object.keys(targetFields).some((key) => {
      return targetFields[key as keyof Address] !== null
    })

    return isValid || 'Введите корректный [полный] адрес'
  }

  public static positiveAmountRequired = (value: string) =>
    !!(value && +String(value).replace(/\D/g, '') > 0) || requiredFieldMsg

  public static autocompleteLocation = (location: Location) =>
    !!(location && !!location.code) || requiredFieldMsg

  public static passport = (value: string) => {
    const seriesRe =
      /(?!0{4})(?!3{4})(?!4{4})(?!5{4})(?!6{4})(?!7{4})(?!8{4})(?!9{4})(?!00)\d{4}$/
    const numberRe =
      /(?!0{6})(?!1{6})(?!2{6})(?!3{6})(?!4{6})(?!5{6})(?!6{6})(?!7{6})(?!8{6})(?!9{6})\d{6}$/
    const [series, number] = value.split(' ')

    if (series && !seriesRe.test(series))
      return 'Введите корректную серию паспорта'
    else if (number && !numberRe.test(number))
      return 'Введите корректный номер паспорта'
    else if (!series || !number) return 'Введите серию и номер паспорта'
    else return true
  }

  public static passportAuthority = (value: string) => {
    return (
      /^[0-9а-яА-ЯЁё"№\-.' ]+$/.test(value) ||
      'Введите корректное место выдачи паспорта'
    )
  }

  public static partsLimit = (value: string) => {
    const parts = value
      .split(/[\s-]/)
      .filter((part: string) => /[^ ]+/g.test(part))
    const dashes = value.match(/-/g) || []

    if (parts.length > 5) return 'Поле должно состоять не более чем из 5 слов'
    else if (dashes.length > 1) return 'Поле может содержать только одно тире'
    else return true
  }
}
export default Rules
