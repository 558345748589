import { Vue, Component } from 'nuxt-property-decorator'

@Component
export default class LoadingMixin extends Vue {
  get isLoading(): boolean {
    return this.$store.state.common.loading
  }

  set isLoading(val: boolean) {
    this.$store.commit('common/SET_LOADING', val)
  }
}
