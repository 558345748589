import { Plugin } from '@nuxt/types'
// import { stringify } from 'qs'
import { initialize } from '~/utils/Api'

const accessor: Plugin = ({ $axios }) => {
  /*   $axios.onRequest((config) => {
    config.paramsSerializer = (params) => stringify(params, { indices: false })
    return config
  })
 */
  initialize($axios)
}

export default accessor
